<template>
  <div>
    <van-nav-bar
        title="商户列表"
        left-text="返回"
        :right-text="navBarRightText"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickAdd"
    />
    <div>
      <van-search
          v-model="searchParamValue"
          show-action
          label="商品"
          placeholder="请输入商品关键词"
          @search="onSearch"
          @clear="onSearchClear"
      >
      </van-search>
    </div>

    <van-index-bar :index-list="indexList" v-on:change="changeIndex">
      <div v-for="item in dataList" :key="item.index" :name="item.index">
        <van-index-anchor :index="item.index">{{ item.text }}</van-index-anchor>
        <div v-for="subItem in item.subItem" :key="subItem.uuid" :subItem="item.uuid">
          <van-cell :title="subItem.username" is-link @click="selectUser(subItem)"/>
        </div>
      </div>
    </van-index-bar>

  </div>
</template>

<script>
import {ContactCard, IndexAnchor, IndexBar, NavBar, Search} from 'vant';
import * as purchaseService from "@/api/admin/purchase";
import * as userAdminService from "@/api/admin/user";
import $store from "@/store";

export default {
  components: {
    [ContactCard.name]: ContactCard,
    [NavBar.name]: NavBar,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Search.name]: Search,
  },
  data() {
    return {
      dataList: [],
      indexList: [],
      searchParamValue: '',
      searchParam: {},
      navBarRightText: ''
    };
  },
  created() {
    this.searchParam.type = $store.state.order.selectEntityAfter.type
    if ('purchase' === $store.state.order.selectEntityAfter.type) {
      this.navBarRightText = '新增'
    }
    this.onLoad()
  },
  methods: {
    changeIndex(index) {
      console.log('order user changeIndex', index)
      this.searchParam.nameKey = index
      if ('purchase' === this.searchParam.type) {
        purchaseService.getAccountUserKeyList(this.searchParam).then(res => {
          this.foldDataList(res.accountUserIndexVos)
        })
      } else {
        userAdminService.getAccountUserKeyList(this.searchParam).then(res => {
          if (res.status === 200) {
            this.foldDataList(res.data)
          }
        })
      }
    },
    foldDataList(data) {
      const getAbsentValues = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
          return !arr2.find(obj => {
            return el.index === obj.index;
          });
        });
        return res;
      };
      let absentValues = getAbsentValues(data, this.dataList)
      if (absentValues.length > 0) {
        absentValues.forEach(i => this.dataList.push(i))
        this.dataList.sort((a, b) => {
          return a.index > b.index ? 1 : -1
        })
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickAdd() {
      if ('purchase' === $store.state.order.selectEntityAfter.type) {
        this.$router.push({path: '/purchase/addFactory'})
      }
    },
    onEdit(userId) {
      this.$router.push({path: '/user/particulars/' + userId})
    },
    selectUser(user) {
      console.log('selectUser', user)
      $store.commit('SET_USER', user)
      if (!this.searchParam.type) {
        this.$router.push({path: '/order'})
      } else {
        this.$router.push({path: $store.state.order.selectEntityAfter.redirect})
      }
    },
    onSearchClear() {
      this.searchParam.username = null
      this.onLoad();
    },
    onSearch() {
      this.searchParam.username = this.searchParamValue;
      this.onLoad();
    },
    onLoad() {
      if ('purchase' === this.searchParam.type) {
        // this.navBarRightText = '新增'
        purchaseService.getAccountUserKeyList(this.searchParam).then(res => {
          this.indexList = res.pinyinKeys
          this.dataList = res.accountUserIndexVos
        })
      } else {
        userAdminService.getAccountUserKey(this.searchParam).then(res => {
          if (res.status === 200) {
            this.indexList = res.data
          }
        })
        userAdminService.getAccountUserKeyList(this.searchParam).then(res => {
          if (res.status === 200) {
            this.dataList = res.data
          }
        })
      }
    }
  },
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
